import { extendTheme } from '@metrewards/react-components';
// import baseTheme from '@metrewards/react-components/dist/themes/base-theme';

// console.log('baseTheme', baseTheme);

const breakpoints = ['568px', '624px', '768px', '1024px', '1280px', '1488px'];

const theme = extendTheme({
  name: 'mi9-auth0',
  fontSizes: [12, 14, 16, 18, 20, 24, 30, 32, 36, 48, 64, 80],
  breakpoints,
  colors: {
    primary: 'rgb(213, 37, 37)',
    fieldText: 'rgb(64, 70, 75)',
    focusFieldBorder: 'rgb(0, 126, 180)',
    hover: {
      primary: 'hsl(0, 70%, 39%)'
    },
    text: '#111111',
    brandGray: {
      100: 'rgb(243, 244, 246)',
      500: 'rgb(59, 59, 59)',
      600: 'rgb(64, 70, 75)',
      800: '#333333',
      900: 'rgb(47, 47, 47)'
    }
  },
  shadows: {
    card: 'rgba(0, 0, 0, 0.1) 0px 5px 10px 0px'
  },
  components: {
    button: {
      baseStyles: {
        width: ['100%', 'auto']
      },
      variants: {
        primary: {
          bg: 'primary',
          fontSize: 1,
          fontWeight: 'bold',
          px: 4,
          '&:hover:enabled': {
            bg: 'hover.primary',
            borderColor: 'hover.primary'
          },
          '&:focus': {
            boxShadow: 'none',
            outline: 'none'
          }
        },
        header: {
          variant: 'primary',
          bg: 'brandGray.900',
          borderColor: 'brandGray.600',
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: 2,
          minHeight: 48,
          p: 4
        }
      }
    },
    form: {
      field: {
        baseStyles: {
          fontSize: 1,
          p: 4,
          height: 48,
          borderRadius: 2,
          border: '1px solid rgb(113, 119, 123)',
          color: 'fieldText',
          '&:focus': {
            borderColor: 'focusFieldBorder',
            bg: 'rgb(230, 242, 247)',
            boxShadow: 'none'
          }
        }
      }
    },
    link: {
      baseStyles: {
        color: 'brandGray.600',
        '&:hover, &:active': {
          textDecoration: 'underline',
          color: 'brandGray.600'
        }
      },
      variants: {
        footerAccordion: {
          width: '100%'
        },
        bottomFooterNav: {
          color: 'white',
          fontSize: 0,
          '&:hover, &:active': {
            textDecoration: 'underline',
            color: 'white'
          }
        }
      }
    },
    accordion: {
      baseStyles: {
        item: {
          bg: 'brandGray.100'
        },
        content: {
          bg: 'brandGray.100'
        },
        button: {
          fontWeight: 'bold',
          bg: 'brandGray.100',
          '&:hover': {
            color: 'brandGray.600'
          },
          '&:focus': {
            boxShadow: 'none',
            color: 'brandGray.600',
            outline: 'none'
          }
        }
      }
    }
  }
});

export default theme;
