import React from 'react';
import { Text, Button } from '@metrewards/react-components';
import { useAuth0 } from '@auth0/auth0-react';

const LogoutButton = () => {
  const { logout } = useAuth0();

  function handleClick() {
    logout({ returnTo: window.location.origin });
  }

  return (
    <Button variant="header" onClick={handleClick}>
      <Text color="white" fontSize={1}>
        Log Out
      </Text>
    </Button>
  );
};

export { LogoutButton };
