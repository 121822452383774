import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@metrewards/react-components';

import App from './App';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import theme from './styles/theme';

ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById('root')
);
