import * as React from 'react';
import { Box } from '@metrewards/react-components';

function FormCard({ header, content }) {
  return (
    <Box boxShadow="card" borderRadius={2}>
      <Box
        px={[4, 6]}
        py={4}
        borderStyle="solid"
        borderWidth={0}
        borderBottomWidth={1}
        borderBottomColor="gray.100"
      >
        {header}
      </Box>
      <Box px={[4, 6]} py={5}>
        {content}
      </Box>
    </Box>
  );
}

export { FormCard };
