import * as React from 'react';
import { Box } from '@metrewards/react-components';

function IconPayment() {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      size={16}
      color="primary"
    >
      <Box
        as="path"
        id="payment-solid-a"
        d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M8.7201,3.3569 L7.1401,3.3679 L7.1461,4.2639 C6.7991,4.3589 6.4881,4.5199 6.2211,4.7419 C5.7271,5.1499 5.4791,5.6789 5.4831,6.3159 C5.4861,6.8719 5.6881,7.3429 6.0841,7.7189 C6.4491,8.0599 6.9911,8.3169 7.6941,8.4809 C8.3821,8.6419 8.7001,8.8109 8.8451,8.9249 C9.0171,9.0609 9.0931,9.2279 9.0931,9.4669 C9.0931,9.7799 9.0071,10.0009 8.8221,10.1649 C8.6271,10.3379 8.3361,10.4269 7.9571,10.4299 C7.5771,10.4299 7.4201,10.3479 7.2251,10.1729 C7.0371,10.0109 6.9481,9.7829 6.9421,9.4499 L6.9301,9.0919 L5.4111,9.0979 L5.3691,9.6239 L5.3701,9.6379 C5.3871,10.2039 5.6461,10.7069 6.1451,11.1369 C6.4941,11.4249 6.7901,11.6229 7.1851,11.7279 L7.1921,12.7479 L8.7721,12.7439 L8.7651,11.7249 C9.1961,11.6189 9.5681,11.4289 9.8711,11.1599 C10.3921,10.7139 10.6551,10.1359 10.6511449,9.4429 C10.6511449,8.8619 10.4251,8.3509 9.9961,7.9629 C9.5911,7.5999 8.9871,7.3219 8.2011,7.1379 C7.6271,7.0059 7.3651,6.8589 7.2441,6.7569 C7.1081,6.6449 7.0451,6.4999 7.0451,6.2969 C7.0421,6.0509 7.1091,5.9029 7.2661,5.7729 C7.4301,5.6329 7.6891,5.5769 8.0191,5.5769 L8.0381,5.5769 C8.3271,5.5769 8.4261,5.6179 8.5801,5.7539 C8.7281,5.8839 8.8241,6.0969 8.8671,6.4119 L8.9171,6.7189 L10.4411,6.7069 L10.4281,6.3219 C10.3921,5.6509 10.1361,5.1099 9.6621,4.7139 C9.3671,4.4739 9.1051,4.3089 8.7241,4.2199 L8.7201,3.3569 Z"
      />
    </Box>
  );
}

export { IconPayment };
