import * as React from 'react';
import { Inline, Link, Badge, Text } from '@metrewards/react-components';

function BadgeLink({ href, isSelected, text, icon }) {
  return (
    <Link
      _hover={{ textDecorationColor: isSelected ? '#111' : 'rgb(213, 37, 37)' }}
      href={href}
    >
      <Badge
        bg={isSelected ? '#FAE9E9' : '#FFF'}
        borderStyle="solid"
        borderWidth={1}
        borderColor={isSelected ? 'primary' : '#CCCED0'}
        py={2}
        px={4}
      >
        <Inline space={2} alignY="center">
          {icon}
          <Text
            color={isSelected ? 'text' : 'primary'}
            fontSize={1}
            fontWeight={isSelected ? 'bold' : 'normal'}
          >
            {text}
          </Text>
        </Inline>
      </Badge>
    </Link>
  );
}

export { BadgeLink };
