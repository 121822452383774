import React from 'react';
import { Container, Box, Image, Inline } from '@metrewards/react-components';

import { AuthNav } from '../auth-nav';

const NavBar = () => {
  return (
    <Box
      bg="brandGray.800"
      px={2}
      height="60px"
      width="100%"
      zIndex="1"
      position="fixed"
      top="0"
    >
      <Container height="100%" display="flex">
        {/* <Columns alignY="center">
          <Column>
            <Image
              width={[140, 187]}
              src="https://storage.googleapis.com/images-met-stg-d7d5f98.met.stg.v8.commerce.mi9cloud.com/theme-images/primary-logo.svg"
            />
          </Column>
          <Column width="content">
            <AuthNav />
          </Column>
        </Columns> */}

        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Box>
            <Image
              width={[140, 187]}
              src="https://storage.googleapis.com/images-met-stg-d7d5f98.met.stg.v8.commerce.mi9cloud.com/theme-images/primary-logo.svg"
            />
          </Box>
          <Box>
            <AuthNav />
          </Box>
        </Box>
        <Inline width="1024px" space={4} alignY="center" align="center" />
      </Container>
    </Box>
  );
};

export { NavBar };
