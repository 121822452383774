import * as React from 'react';
import { Box } from '@metrewards/react-components';

function IconProfile() {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      size={16}
      color="primary"
    >
      <Box
        as="path"
        id="account-solid-a"
        d="M13.4825909,11.4373007 L10.5750455,10.1638026 C10.3007727,10.0434549 10.1305455,9.80182157 10.1305455,9.53299281 L10.1305455,8.63147869 C10.2002273,8.55676931 10.2734091,8.4717444 10.3491364,8.37765433 C10.7261818,7.9112677 11.0287727,7.39174049 11.2483182,6.83126379 C11.6778636,6.65871324 11.9565909,6.28672926 11.9565909,5.86691877 L11.9565909,4.8003563 C11.9565909,4.54340602 11.8468182,4.29458312 11.6520909,4.10015116 L11.6520909,2.68161057 C11.6692727,2.53500512 11.7360909,1.66193684 11.0154091,0.941725838 C10.3905,0.31685527 9.37581818,0.0002 8,0.0002 C6.62418182,0.0002 5.6095,0.31685527 4.98459091,0.941413246 C4.26390909,1.66193684 4.33072727,2.53500512 4.34790909,2.68161057 L4.34790909,4.10015116 C4.15318182,4.29458312 4.04340909,4.54340602 4.04340909,4.8003563 L4.04340909,5.86691877 C4.04340909,6.19138882 4.21172727,6.49397747 4.49904545,6.69591163 C4.77809091,7.66306998 5.36131818,8.39203354 5.56527273,8.62585205 L5.56527273,9.50829808 C5.56527273,9.76618613 5.40459091,10.0031305 5.14622727,10.1269168 L2.43118182,11.4244845 C1.54822727,11.8467957 1,12.6554701 1,13.5366658 L1,14.4003563 C1,15.665727 5.57895455,16.0002 8,16.0002 C10.4210455,16.0002 15,15.665727 15,14.4003563 L15,13.5888686 C15,12.6720375 14.4183636,11.8474209 13.4825909,11.4373007"
      />
    </Box>
  );
}

export { IconProfile };
