import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import axios from 'axios';
import {
  Container,
  Box,
  Image,
  Stack,
  Text,
  Inline
} from '@metrewards/react-components';

import {
  Loading,
  UpdatePasswordCard,
  BadgeLink,
  IconProfile,
  IconAddress,
  IconPayment,
  Footer
} from '../components';
import { UpdateEmailCard } from '../components/update-email-card';

export const Profile = () => {
  const { user } = useAuth0();
  const {
    sub,
    email,
    picture,
    given_name: givenName,
    family_name: familyName
  } = user;
  const { getAccessTokenSilently } = useAuth0();

  const [newPassword, setNewPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [otp, setOtp] = useState('');

  const [show, setShow] = useState(false);

  const [toastText, setToastText] = useState({
    theader: '',
    tbodyText: '',
    variant: ''
  });

  const [processing, setProcessing] = useState({
    password: false,
    email: false,
    otp: false
  });

  const [tabKey, setTabKey] = useState('step1');
  const [emailOK, setEmailOK] = useState(false);
  const handleSendOtp = async e => {
    e.preventDefault();
    // now post the changed email to auth0 - to trigger otp sent to new email
    setProcessing({ password: false, email: true, otp: false });
    const payload = JSON.stringify({
      new_email: newEmail
    });
    const accessToken = await getAccessTokenSilently();
    let output;

    const options = {
      method: 'post',
      url: `${process.env.REACT_APP_AUTH0_API}/user/email`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8'
      },
      data: payload
    };
    try {
      const res = await axios(options);
      output = await res.data;
      setProcessing({ password: false, email: false, otp: false });
      console.log(output);

      if (output.user._id) {
        setToastText({
          theader: 'One Time Password Sent',
          tbodyText: 'Please check your email',
          variant: 'success'
        });
        setShow(true);
        setTabKey('step2');
      } else {
        setToastText({
          theader: 'Error',
          tbodyText: output.message,
          variant: 'danger'
        });
        setShow(true);
      }
    } catch (err) {
      output = err;
      console.error(err);
    }
  };

  const handleVerifyOtp = async e => {
    e.preventDefault();
    // now post the changed email to auth0 - to trigger otp sent to new email
    if (otp === '') {
      setOtp('');
      setToastText({
        theader: 'Error...',
        tbodyText: 'Email cannot be blank.',
        variant: 'danger'
      });
      setShow(true);
      return;
    }
    setProcessing({ password: false, email: false, otp: true });
    const payload = JSON.stringify({
      new_email: newEmail,
      otp,
      current_user_id: sub
    });
    const accessToken = await getAccessTokenSilently();
    let output;

    const options = {
      method: 'post',
      url: `${process.env.REACT_APP_AUTH0_API}/user/verifyOtp`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8'
      },
      data: payload
    };
    try {
      const res = await axios(options);
      output = await res.data;
      setProcessing({ password: false, email: false, otp: false });
      if (output.user.identities[0].user_id) {
        setToastText({
          variant: 'success',
          theader: 'Email Updated!',
          tbodyText: 'Please logout and login again'
        });
        setShow(true);
      }
    } catch (err) {
      output = err;
      console.error(err);
    }
  };

  const handlePasswordUpdate = async e => {
    console.log('WOO');
    e.preventDefault();
    if (newPassword === '') {
      setNewPassword('');
      setToastText({
        theader: 'Error...',
        tbodyText: 'Password cannot be blank.',
        variant: 'danger'
      });
      setShow(true);
      return;
    }
    setProcessing({ password: true, email: false, otp: false });
    // now post the changed password to auth0
    const payload = JSON.stringify({
      user_id: sub,
      new_password: newPassword
    });
    const accessToken = await getAccessTokenSilently();
    let output;

    const options = {
      method: 'post',
      url: `${process.env.REACT_APP_AUTH0_API}/user/password`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8'
      },
      data: payload
    };
    try {
      const res = await axios(options);
      output = await res.data;
      setProcessing({ password: false, email: false, otp: false });
      if (output.user.identities[0].user_id) {
        setNewPassword('');
        setToastText({
          variant: 'success',
          theader: 'Password Updated.',
          tbodyText: 'Please logout and login again'
        });
        setShow(true);
      }
    } catch (err) {
      output = err;
      console.log(err);
    }
  };

  const handleChange = e => {
    setNewPassword(e.target.value);
  };

  const handleEmailChange = e => {
    const currentEmail = e.target.value;
    if (currentEmail.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      setEmailOK(true);
      setNewEmail(currentEmail);
    } else {
      setEmailOK(false);
      setNewEmail('');
    }
  };

  const handleOtpChange = e => {
    setOtp(e.target.value);
  };

  // const access_token = await getAccessTokenSilently();

  return (
    <Box
      pt={94}
      height="100vh"
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Container maxWidth={1} px={4} pb={8} width="100%">
        <Stack>
          <Stack space={6}>
            <Stack space={7} align="center">
              <Stack space={4} align="center">
                <Image src={picture} borderRadius={100} width={64} />
                <Text fontSize={7} fontWeight="bold" color="text">
                  Hello {givenName}
                </Text>
              </Stack>
              <Inline space={2} align="center">
                <BadgeLink
                  href="http://new.igashop.com.au/my-account/profile"
                  isSelected
                  text="Profile"
                  icon={<IconProfile />}
                />
                <BadgeLink
                  href="http://new.igashop.com.au/my-account/addresses"
                  isSelected={false}
                  text="Addresses"
                  icon={<IconAddress />}
                />
                <BadgeLink
                  href="http://new.igashop.com.au/my-account/payment-methods"
                  isSelected={false}
                  text="Payment Methods"
                  icon={<IconPayment />}
                />
              </Inline>
            </Stack>
            <Box>
              <Alert
                variant={toastText.variant}
                onClose={() => setShow(false)}
                dismissible
                show={show}
              >
                <Alert.Heading>{toastText.theader}</Alert.Heading>
                {toastText.tbodyText}
              </Alert>
            </Box>
          </Stack>
          <Stack space={6}>
            <UpdatePasswordCard
              onPasswordChange={handleChange}
              buttonDisabled={!newPassword.length || processing.password}
              onButtonClick={handlePasswordUpdate}
            />
          </Stack>
        </Stack>
      </Container>
      <Footer />
    </Box>
  );
};

export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <Loading />
});
