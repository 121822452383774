import * as React from 'react';
import {
  Box,
  Text,
  Label,
  Input,
  Stack,
  Button
} from '@metrewards/react-components';

import { FormCard } from '../form-card';
import { IconProfile } from '../icon-profile';

function UpdatePasswordCard({
  onPasswordChange,
  buttonDisabled,
  onButtonClick
}) {
  return (
    <FormCard
      header={
        <Box display="flex" alignItems="center">
          <IconProfile />

          <Text fontWeight="bold" sx={{ ml: 4 }}>
            Update Password
          </Text>
        </Box>
      }
      content={
        <Stack space={4}>
          <Stack space={2}>
            <Label fontSize={1} fontWeight="bold">
              Enter Your New Password
            </Label>
            <Input
              placeholder="Password"
              type="password"
              onChange={onPasswordChange}
            />
          </Stack>

          <Button disabled={buttonDisabled} onClick={onButtonClick}>
            Update Password
          </Button>
        </Stack>
      }
    />
  );
}

export { UpdatePasswordCard };
