import * as React from 'react';
import { Box } from '@metrewards/react-components';

function IconAddress() {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      size={16}
      color="primary"
    >
      <Box
        as="path"
        id="addresses-a"
        d="M12.5,0 C13.6045695,-2.02906125e-16 14.5,0.8954305 14.5,2 L14.5,14 C14.5,15.1045695 13.6045695,16 12.5,16 L4.5,16 C3.3954305,16 2.5,15.1045695 2.5,14 L2.5,11.5 L2.25,11.5 C1.83578644,11.5 1.5,11.1642136 1.5,10.75 C1.5,10.3357864 1.83578644,10 2.25,10 L2.5,10 L2.5,8.5 L2.25,8.5 C1.83578644,8.5 1.5,8.16421356 1.5,7.75 C1.5,7.33578644 1.83578644,7 2.25,7 L2.5,7 L2.5,5.5 L2.25,5.5 C1.83578644,5.5 1.5,5.16421356 1.5,4.75 C1.5,4.33578644 1.83578644,4 2.25,4 L2.5,4 L2.5,2 C2.5,0.8954305 3.3954305,2.02906125e-16 4.5,0 L12.5,0 Z M13,1.5 L4,1.5 L4,14.5 L13,14.5 L13,1.5 Z M8.5,4 C9.8805,4 11,5.1195 11,6.5 C11,9 8.5,12 8.5,12 C8.5,12 6,9 6,6.5 C6,5.1195 7.1195,4 8.5,4 Z M8.5,5 C7.6715,5 7,5.6715 7,6.5 C7,7.3285 7.6715,8 8.5,8 C9.3285,8 10,7.3285 10,6.5 C10,5.6715 9.3285,5 8.5,5 Z"
      />
    </Box>
  );
}

export { IconAddress };
