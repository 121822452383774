import React from 'react';
import { Switch } from 'react-router-dom';
import { Box, Spinner, Text, Stack } from '@metrewards/react-components';
import { useAuth0 } from '@auth0/auth0-react';

import { NavBar } from './components';
import { Profile } from './views';
import ProtectedRoute from './auth/protected-route';
// import { LogoutButton } from './components/logout-button';
// import { AuthNav } from './components/auth-nav';

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <Box
        width="100vw"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Stack align="center" space={4}>
          <Spinner size={50} />
          <Text fontWeight="bold">Retreiving Profile...</Text>
        </Stack>
      </Box>
    );
  }

  return (
    <>
      <NavBar />
      <Switch>
        <ProtectedRoute path="/" exact component={Profile} />
      </Switch>
    </>
  );
};

export default App;
