import React from 'react';

// import { useAuth0 } from '@auth0/auth0-react';
// import LoginButton from '../login-button';
import { LogoutButton } from '../logout-button';

const AuthNav = () => {
  // const { isAuthenticated } = useAuth0();
  // console.log(isAuthenticated);
  return <LogoutButton />;
};
export { AuthNav };
